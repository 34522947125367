<template>
    <div class="home mt-5">
        <NotifyAboutLowCredits />

        <fullscreen-loading v-if="loading"></fullscreen-loading>

        <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
            <div class="row">
                <div class="fusion-title">
                    <h3 class="h3-green" style="font-size:50px;">Ondertekeningen</h3>
                </div>
            </div>

            <div class="row mt-2">
                <p>Hieronder vind je alle ondertekening terug met hun desbetreffende status. Je kan via het rechter filter menu eenvoudig zoeken en filteren. </p>
            </div>

            <div class="row">
                <div class="col-md-3 mb-3">
                    <router-link :to="{name: 'NewSigning'}" class="btn btn-primary w-100 mb-3" id="nieuwe-ondertekening-link-side-menu-groot">
                        <font-awesome-icon icon="paper-plane"></font-awesome-icon>
                        Nieuwe ondertekening starten
                    </router-link>

                    <div class="summary-box p-15 mb-3">
                        <h2 class="title-heading-left mb-3 float-start "> Zoeken</h2>
                        <div class="row">
                            <div class="col-12">
                                <VueCtkDateTimePicker
                                    :label="'Selecteer een verzenddatum'"
                                    :color="'#004188'"
                                    :only-date="true"
                                    :button-color="'#004188'"
                                    format="YYYY-MM-DD"
                                    formatted="YYYY-MM-DD"
                                    v-model="filter.sent_at"
                                    @input="search"
                                />
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12">
                                <input
                                    type="text"
                                    class="fusion-form-input form-control-sm"
                                    placeholder="Documenten"
                                    v-model="filter.signables"
                                    @change="debounceSearch"
                                    @keyup="debounceSearch"
                                />
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12">
                                <input
                                    type="text"
                                    class="fusion-form-input form-control-sm"
                                    placeholder="Ondertekenaars"
                                    v-model="filter.signers"
                                    @change="debounceSearch"
                                    @keyup="debounceSearch"
                                />
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12">
                                <multiselect
                                    v-model="filter.states"
                                    :options="searchStates"
                                    @input="search"
                                    label="name"
                                    track-by="id"
                                    :multiple="true"
                                    :selectLabel="'Klik om te selecteren'"
                                    :deselectLabel="'Klik om te verwijderen'"
                                    :selectedLabel="'Geselecteerd'"
                                    :placeholder="'Selecteer een status'"
                                    :searchable="false"
                                />
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12">
                                <button class="btn btn-primary" style="width: 100%;" @click="search">Zoeken</button>
                            </div>
                        </div>
                    </div>

                    <SettingsMenu style="height: auto!important;"/>
                </div>

                <div class="col-md-9">
                    <div class="row">
                        <div v-if="!signrequests.data.length && !loading" class="col-12">
                            <div class="alert alert-info">
                                Je hebt nog geen ondertekeningen. Klik hieronder op "nieuwe ondertekening starten" om te beginnen met je eerste ondertekening
                            </div>
                            <router-link :to="{name: 'NewSigning'}" class="btn btn-success btn-caps">
                                <font-awesome-icon icon="paper-plane"></font-awesome-icon>
                                Nieuwe ondertekening starten
                            </router-link>
                        </div>
                        <div class="col-12 mb-3" v-for="(signrequest) in signrequests.data" :key="signrequest.id">
                            <div class="card signcard">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="float-start mb-3">
                                                <h2 class="h3-card-header">Ondertekening voor {{ getSigners(signrequest) }} </h2>
                                                <div><small>Datum: {{ signrequest.created_at | moment('LL') }}</small></div>
                                            </div>
                                            <router-link :to="`/ondertekening/${signrequest.id}`" class="btn btn-sm btn-primary float-end">
                                                <font-awesome-icon icon="search"/>
                                            </router-link>
                                        </div>
                                    </div>

                                    <div class="row ">
                                        <div class="col-3">
                                            Status:
                                        </div>
                                        <div class="col-8">
                                            <span class="badge " v-if="signrequest.state_id" :class="getStateClassFromID(signrequest.state_id)">
                                                <span v-if="signrequest.state_id === 5">Getekend</span>
                                                <span v-else>{{ getStateNameFromID(signrequest.state_id) }}</span>
                                            </span>

                                        </div>
                                    </div>

                                    <div class="row ">
                                        <div class="col-3">
                                            Documenten:
                                        </div>
                                        <div class="col-8">
                                            <em>{{ getSignables(signrequest) }}</em>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Pagination :pagination="signrequests" @change="getSignRequests()"/>
        </div>
    </div>
</template>

<script>

    import {mapState} from 'vuex';
    import StateMixin from '@/mixins/StateMixin';
    import FullscreenLoading from '@/components/FullscreenLoading';
    import Pagination from '@/components/Pagination';
    import {debounce} from 'lodash';
    import SettingsMenu from '../components/SettingsMenu';
    import NotifyAboutLowCredits from "../components/notifyAboutLowCredits.vue";

    export default {

        mixins: [
            StateMixin
        ],

        components: {
            NotifyAboutLowCredits,
            SettingsMenu,
            Pagination,
            FullscreenLoading
        },

        computed: {
            ...mapState({
                user: state => state.user,
                ceremony: state => state.ceremony,
            }),

            searchStates() {
                return this.states.filter(state => state.searchable);
            }
        },

        data() {
            return {
                loading: false,
                order: 'id,desc',
                signrequests: {
                    data: []
                },

                filter: {
                    sent_at: null,
                    signables: null,
                    signers: null,
                    states: []
                }
            }
        },

        mounted() {
            this.loadFiltersFromUrl();
            this.getSignRequests();
        },

        methods: {
            loadFiltersFromUrl() {
                const statesFromUrl = (this.$route.query?.states || '').split(',');

                this.filter = {
                    sent_at: this.$route.query.sent_at || null,
                    signables: this.$route.query.signables || null,
                    signers: this.$route.query.signers || null,
                    states: this.searchStates.filter(state => {
                        return statesFromUrl.indexOf(String(state.id)) !== -1
                    }),
                }
            },

            async getSignRequests(page = undefined) {
                this.loading = true;

                try {
                    const filters = [];

                    if (this.filter.sent_at) {
                        filters.push({
                            field: 'created_at',
                            type: 'date',
                            eq: this.filter.sent_at
                        });
                    }

                    if (this.filter.signers) {
                        filters.push({
                            field: 'signers',
                            like: '%' + this.filter.signers + '%'
                        });
                    }

                    if (this.filter.signables) {
                        filters.push({
                            field: 'signables',
                            like: '%' + this.filter.signables + '%'
                        });
                    }

                    if (this.filter.states && this.filter.states.length) {
                        filters.push({
                            field: 'state_id',
                            in: this.filter.states.map(state => state.id)
                        });
                    }

                    const response = await this.$http.get(this.$root.api_url + '/api/sign-requests', {
                        params: {
                            page: page || this.signrequests.current_page,
                            order: this.order,
                            limit: 10,
                            filters: filters.length ? JSON.stringify(filters) : undefined
                        }
                    });

                    this.signrequests = response.data;

                } catch (exception) {
                    this.errormessage = exception
                    console.error(exception);
                }

                this.loading = false;
            },


            getSigners(signrequest) {
                return signrequest.signers.map(signer => {
                    return `${signer.firstname} ${signer.lastname}`
                }).join(', ')
            },

            getSignables(signrequest) {
                return signrequest.signables.map(signable => {
                    return signable.name
                }).join(', ')
            },

            debounceSearch: debounce(function () {
                this.search()
            }, 500),

            search() {
                this.getSignRequests(1);

                this.$router.replace({
                    query: {
                        sent_at: this.filter.sent_at || undefined,
                        signers: this.filter.signers || undefined,
                        signables: this.filter.signables || undefined,
                        states: (this.filter.states && this.filter.states.length ? this.filter.states.map(state => state.id).join(',') : undefined)
                    }
                });
            }
        }
    }
</script>

<style type="text/css">


    @media only screen and (max-width: 1024px) {
        .fusion-title.fusion-title-6 {
            margin-top: 10px !important;
            margin-right: 0px !important;
            margin-bottom: 15px !important;
            margin-left: 0px !important;
        }
    }

    @media only screen and (max-width: 640px) {
        .fusion-title.fusion-title-6 {
            margin-top: 10px !important;
            margin-right: 0px !important;
            margin-bottom: 10px !important;
            margin-left: 0px !important;
        }
    }</style>
