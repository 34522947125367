<template>
    <div class="top-0 w-100 notify-credits" v-if="displayComponent">
        <div class="d-flex justify-content-between">
            <span>
                {{ company.credits === 0 ? 'Je hebt geen credits' :  'Je hebt nog maar ' + company.credits + ' credits over'}}. Koop credits of upgrade om te kunnen blijven ondertekenen.
                <router-link :to="`/instellingen/credits`" class="btn btn-success btn-caps ms-3 btn-sm">Kopen</router-link>
            </span>
            <a href="javascript:void(0)" @click="closeComponent">
                &#10006;
            </a>
        </div>
    </div>
</template>
<script>
    import {mapState} from "vuex";

    export default {

        computed: {
            ...mapState({
                company: state => state.company,
            }),

            displayComponent() {

                if (this.hideComponent) {
                    return false;
                }

                if (!this.company) {
                    return false;
                }

                if (window.location.pathname !== '/') {
                    return false;
                }

                return this.company.credits < 5;
            }
        },
        data() {
            return {
                hideComponent: false,
            }
        },

        methods: {
            closeComponent() {
                this.hideComponent = true;
            }
        }

    }
</script>

<style scoped>
    .notify-credits{
        position: fixed;
        background:#f6f8fb;
        z-index: 10;
        box-shadow: 0 0 30px #00000030;
        left: 0px;
        padding: 5px 30px;
        max-width: 100vw;
    }

    @media screen and (max-width: 600px) {
        .notify-credits{
            padding: 5px !important;
        }
    }
</style>